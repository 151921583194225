import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {FormGroup} from "@angular/forms";
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ContactFormV2Service {

  constructor(private http: HttpClient) { }

  public submitCustomerMessage(form:any): Observable<void> {
    return this.http.post<void>('https://stamfordpolytechnic.org/customerMessage',form);
  }

}

