import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { WidgetsModule } from '../../Widgets/Widgets.module';
import { CourseworkComponent } from './Coursework/Coursework.component';
import { CourseworkRoutes } from './Coursework.routing';
import {ScrollPanelModule, SidebarModule, TreeModule} from "primeng/primeng";

@NgModule({
  declarations: [CourseworkComponent],

    imports: [
        CommonModule,
        WidgetsModule,
        RouterModule.forChild(CourseworkRoutes),
        SidebarModule,
        TreeModule,
        ScrollPanelModule
    ]

})
export class CourseworkModule { }
