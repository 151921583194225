import { Component, OnInit } from '@angular/core';
import { DropletService } from '../../Services/Droplet.service';

@Component({
	selector: 'app-home',
	templateUrl: './Home.component.html',
	styleUrls: ['./Home.component.scss']
})

export class HomeComponent implements OnInit {

	gridOverlay  		: any;
	serviceItems 		: any;
	testimonialData	: any;
	counterContent    : any;

	/**
	  * Recent Work Content Heading
	  */
	recentWorkHeading : any = {
		title : "Recent Work",
		content : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
	}

	/**
	  * Explore Studio content
	  */
	exploreStudio : any = [
		"assets/images/nannette_jellyfish.JPG",
		"assets/images/zhanna_boat.jpg",
		"assets/images/students_yale_boat.JPG"
	]

	/**
	  * Client Says Content Heading
	  */
	clientSaysHeading : any = {
		title : "Testimonials",
		content : "Hear from our past Stamford Polytechnic parents and alumni"
	}

	/**
	  * Content of Contact section
	  */
	contactContent : any = {
		title : "Start Realizing Your Academic Goals Today!",
		content : "Call or e-mail us, or provide us with your contact information, and we will reach out to you.",
		email : "zhannaw@stamfordpolytechnic.org",
		contactNumber : "(203) 321 3165",
		contactTo : "2033213165" ,
		image : "assets/images/zhanna_suit_crop.jpg"
	}

	constructor(public service : DropletService) { }

	ngOnInit() {

		//blog overlay content
		this.service.getBlogContent().
			subscribe( response => { this.gridOverlay = response },
	                 err 	  =>  console.log(err),
	                 ()       =>  this.gridOverlay
	           	);

		//service content
		this.service.getServiceContent().
			subscribe( response => {this.serviceItems = response },
					   err      => console.log(err),
					   ()       => this.serviceItems
					);

		//counter content
		this.service.getCounterContent().
			subscribe( response => {this.counterContent = response },
					   err      => console.log(err),
					   ()       => this.counterContent
					);

		//testimonial content
		this.service.getTestimonialContent().
			subscribe( response => {this.testimonialData = response },
					   err      => console.log(err),
					   ()       => this.testimonialData
					);
	}
}
