import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class CourseworkService {

  constructor(private http: HttpClient) { }

  public fetchCourses(): Observable<any> {
    return this.http.get('student/courses',{observe: 'response'});
  }
}
