import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService} from "./Login.service";

@Component({
	selector: 'app-login',
	templateUrl: './LogIn.component.html',
	styleUrls: ['./LogIn.component.scss']
})

export class LoginComponent implements OnInit {

	public  form 	: FormGroup;
	emailPattern   : string    = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";

	constructor(private fb : FormBuilder, private loginService: LoginService,
					private router : Router) { }

	ngOnInit() {
		this.form = this.fb.group({
  			username 			: ['', [Validators.required,Validators.pattern(this.emailPattern)]] ,
  			password       : ['', Validators.required]
		});
	}

	//login method is used when form field is valid then router will be navigate to the home page.
	logIn() {
	  console.log("LOGGING IN!");

		if(this.form.valid){
      this.loginService.submitFormLogin(this.form).subscribe(
        response => {
          console.log(response.status);

        },response=>{
          console.log("Response code "+response.status);
          if(response.status=='200' || response.status=='0') {
            this.router.navigate(['/student/coursework']);
          }else{
            console.log("BAD LOGIN CODE");
          }
        }
      );
		}else{
			for(let i in this.form.controls)
				{
					this.form.controls[i].markAsTouched();
				}
		}
	}
}
