import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {ContactFormV2Service} from "../ContactFormV2/contact-form-v2.service";

@Component({
	selector: 'app-contact-form',
	templateUrl: './ContactForm.component.html',
	styleUrls: ['./ContactForm.component.scss']
})

export class ContactFormComponent implements OnInit {

	public  form 	: FormGroup;
	emailPattern   : string    = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  submitResult  : string;
  showSubmitted: boolean = false;

	constructor(private fb : FormBuilder, private contactService: ContactFormV2Service) { }

  onSubmit() {
    console.log("Submission happened.")
    this.contactService.submitCustomerMessage(this.form.value).subscribe(
      err => {console.error(err);},
      ()  => {console.log("SUBMITTED THE FORM!");
        this.showSubmitted=true;
      }
    );
  };

	ngOnInit() {

		this.form = this.fb.group({
  			fname  			: ['', Validators.required],
  			email 			: ['', [Validators.required,Validators.pattern(this.emailPattern)]] ,
  			phone  : ['', Validators.required],
  			message 	: ['', Validators.required]
		});
	}

}
