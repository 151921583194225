import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {FormGroup} from "@angular/forms";



@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  public submitFormLogin(formSubmit:FormGroup): Observable<any> {

    const headers = new HttpHeaders()
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    let postData = new FormData();
    postData.append('username' , formSubmit.get('username').value);
    postData.append('password' , formSubmit.get('password').value);

    return this.http.post<boolean>('login',postData,{observe: 'response'});
    //return this.http.post<boolean>('http://localhost:5000/login',postData,{observe: 'response'});

  }
}









