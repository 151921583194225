import { Component, OnInit } from '@angular/core';
import {TreeNode} from 'primeng/api';
import {CourseworkService} from "./coursework.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Router} from "@angular/router";


@Component({
  selector: 'app-coursework',
  templateUrl: './coursework.component.html',
  styleUrls: ['./coursework.component.scss']
})
export class CourseworkComponent implements OnInit {

  courses: TreeNode[];
  selectedCourse: TreeNode;

  display: any;
  displayContent: SafeResourceUrl;

  constructor( private studentService: CourseworkService, private sanitizer: DomSanitizer,private router : Router) { }


  nodeSelect(event:any) {
    console.log("USER SELECTED "+event.node.data);
    this.displayContent =
    this.sanitizer.bypassSecurityTrustResourceUrl('https://stamfordpolytechnic.org/fleem/'+event.node.data);
  }

  ngOnInit() {
    this.display=true;

    this.displayContent=this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/sp_door_green_small.png');

    this.studentService.fetchCourses().subscribe(
      response=>{
        console.log("Response status getting courses = "+response.status);
        this.courses=response.body.data;
    },response=>{
        console.log("Response status 2 = "+response.status);
        //No good, force login
        this.router.navigate(['/session/login']);
    });
   }
 }
