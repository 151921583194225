import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactFormV2Service} from "../../Form/ContactFormV2/contact-form-v2.service";

@Component({
  selector: 'app-subscribe',
  templateUrl: './Subscribe.component.html',
  styleUrls: ['./Subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  public  form 	: FormGroup;
  emailPattern   : string    = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  showSubmitted: boolean = false;

  constructor(private fb : FormBuilder, private contactService: ContactFormV2Service) { }

  onSubmit() {
    this.form.controls['message'].setValue('SUBSCRIPTION');
    this.contactService.submitCustomerMessage(this.form.value).subscribe(
      err => {console.error(err);},
      ()  => {console.log("SUBMITTED THE FORM!");
        this.showSubmitted=true;
      }
    );
  };

  ngOnInit() {
    this.form = this.fb.group({
      email 			: ['', [Validators.required,Validators.pattern(this.emailPattern)]] ,
      message		   : ['']
    });
  }

}
